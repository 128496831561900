<template>
  <div>
    <el-dialog
      title="设置评价规则"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="cancel"
    >
      <div class="punchRules">
        <div class="title">是否评价</div>
        <div class="ruleDetail">
          <el-radio v-model="form.isEvaluate" :label="1">是</el-radio>
          <el-radio v-model="form.isEvaluate" :label="0">否</el-radio>
        </div>
        <div class="tips">勾选后将影响前台用户是否可以进行评价,不影响用户查看</div>
      </div>
      <div class="punchRules">
        <div class="title">评价规则</div>
        <div class="slideDetail">
          <el-radio v-model="form.evaluateRule" :label="0">培训班结训可以进行评价</el-radio>
          <div class="tipsTitle">勾选此项仅已结束状态可以进行评价</div>
          <el-radio v-model="form.evaluateRule" :label="1">开班后可以进行评价</el-radio>
          <div class="tips">勾选此项待培训、培训中、已结训状态都可以进行评价</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setClassGetRule, setClassUpdateRule } from '@/api/class'
import to from 'await-to'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        isEvaluate: -1,
        evaluateRule: -1,
        configId: '',
        classId: '',
      },
    }
  },
  created() {},
  methods: {
    show(classId) {
      this.dialogVisible = true
      this.form.classId = classId
      this.setClassGetRuleData(classId)
    },
    async setClassGetRuleData(classId) {
      const [res, err] = await to(setClassGetRule({ classId: classId }))
      if (err) return this.$message.warning(err.msg)
      if (res.data) {
        this.form = {
          isEvaluate: res.data.isEvaluate,
          evaluateRule: res.data.evaluateRule,
          configId: res.data.configId,
          classId: res.data.classId,
        }
        console.log(this.form)
      }
    },
    async setClassUpdateRuleData() {
      const [, err] = await to(setClassUpdateRule(this.form))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('设置成功')
      this.dialogVisible = false
    },
    handleSubmit() {
      console.log(this.form)
      this.setClassUpdateRuleData()
    },
    cancel() {
      let obj = {
        isEvaluate: -1,
        evaluateRule: -1,
        configId: '',
        classId: '',
      }
      this.dialogVisible = false
      this.form = obj
    },
  },
}
</script>

<style lang="scss" scoped>
.punchRules {
  padding-left: 00px;
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .ruleDetail {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .tips {
    margin-top: 10px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(144, 147, 153, 0.65);
    line-height: 22px;
  }

  .slideDetail {
    display: flex;
    flex-direction: column;

    .tipsTitle {
      margin-top: 10px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(144, 147, 153, 0.65);
      line-height: 22px;
      margin-bottom: 15px;
    }
  }
}
.bgc {
  background-color: #ff7b33 !important;
  color: #fff !important;
}
</style>
