<template>
  <div>
    <el-dialog
      title="设置学习规则"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="cancel"
    >
      <div class="punchRules">
        <div class="title">播放规则</div>
        <div class="ruleDetail">
          <div class="detailItem">
            <el-checkbox v-model="form.isDrag">课程学习完成后可以拖动进度条</el-checkbox>
          </div>
          <div class="detailItem">
            <el-checkbox v-model="form.isSpeed">课程学习完成后可以播放倍速</el-checkbox>
          </div>
          <div class="detailItem">
            <el-checkbox v-model="form.isEndClass">结训后班级继续向学员开放</el-checkbox>
          </div>
          <div class="detailItem">
            <el-checkbox v-model="form.isAlwaysDoubleSpeed"
              >课程可以倍速播放（首次学习也可以倍速）
            </el-checkbox>
            <div>（注意：勾选后详细课程记录时间上会有出现偏差）</div>
          </div>
        </div>
      </div>
      <div class="punchRules">
        <div class="title">打卡规则</div>
        <div class="slideDetail">
          <el-checkbox v-model="form.isSlide">滑动打卡</el-checkbox>
          <div v-if="form.isSlide" class="info">
            <div class="infoItem">
              每个视频打卡次数
              <span
                v-for="(item, index) in list"
                :key="index"
                class="num"
                :class="{ bgc: choiceIndex == index }"
                @click="changeClockNum(index)"
                >{{ item }}</span
              >
              次数
            </div>
            <div class="infoItem">
              <el-radio-group v-model="form.punchPattern">
                <el-radio :label="1">固定打卡点</el-radio>
                <el-radio :label="2">随机打卡点</el-radio>
              </el-radio-group>
            </div>
            <div v-if="form.punchPattern == 1" class="infoItem">
              <div class="text">固定在视频的x%弹出打卡弹窗</div>
              <div class="progrseeWrap">
                <div v-for="(citem, cindex) in form.clockList" :key="cindex" class="progrsee">
                  <div>第{{ cindex + 1 }}打卡点</div>
                  <el-input v-model="citem.val" class="input-width"></el-input>
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="punchRules">
        <div class="title">考试规则</div>
        <div class="ruleDetail">
          <div class="detailItem">
            <el-checkbox v-model="form.isExamDependCourse">课程学习完成可以参加考试</el-checkbox>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { searchClassClock, setClassClock } from '@/api/class'
import to from 'await-to'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        isDrag: true,
        isSpeed: true,
        isEndClass: true,
        isSlide: false,
        isAlwaysDoubleSpeed: false,
        isExamDependCourse: true,
        clockList: [{ val: '' }],
        punchNum: 1, //	打卡次数
        punchPattern: '', //打卡模式
        punchPoint: '', //打卡点
        punchType: '', //打卡类型
        afterStudyDrag: 0,
        classFinishContinueLearn: 0,
        doubleSpeed: 0,
        alwaysDoubleSpeed: 0,
        classId: '',
      },
      list: ['1', '2', '3'],
      choiceIndex: 0,
    }
  },
  created() {},
  methods: {
    show(classId) {
      this.dialogVisible = true
      this.form.classId = classId
      this.searchClassClockData(classId)
    },
    async searchClassClockData(classId) {
      const [res, err] = await to(searchClassClock({ classId: classId }))
      if (err) return this.$message.warning(err.msg)
      if (res.data) {
        this.form = {
          isDrag: true,
          isSpeed: true,
          isEndClass: true,
          isSlide: false,
          isAlwaysDoubleSpeed: false,
          isExamDependCourse: res.data.isExamDependCourse,
          clockList: [{ val: '' }],
          punchNum: res.data.punchNum,
          punchPattern: res.data.punchPattern,
          punchPoint: res.data.punchPoint,
          punchType: res.data.punchType,
          afterStudyDrag: res.data.afterStudyDrag,
          classFinishContinueLearn: res.data.classFinishContinueLearn,
          doubleSpeed: res.data.doubleSpeed,
          classId: res.data.classId,
          alwaysDoubleSpeed: res.data.alwaysDoubleSpeed,
        }
        if (res.data.punchNum !== 0) {
          this.choiceIndex = Number(res.data.punchNum) - 1
        }
        if (res.data.punchType == 1) {
          this.form.isSlide = true
        } else {
          this.form.isSlide = false
        }
        if (res.data.afterStudyDrag == 1 || res.data.afterStudyDrag == null) {
          this.form.isDrag = true
        } else {
          this.form.isDrag = false
        }
        if (res.data.doubleSpeed == 1 || res.data.doubleSpeed == null) {
          this.form.isSpeed = true
        } else {
          this.form.isSpeed = false
        }
        if (res.data.alwaysDoubleSpeed == 1 || res.data.alwaysDoubleSpeed == null) {
          this.form.isAlwaysDoubleSpeed = true
        } else {
          this.form.isAlwaysDoubleSpeed = false
        }
        if (res.data.classFinishContinueLearn == 1 || res.data.classFinishContinueLearn == null) {
          this.form.isEndClass = true
        } else {
          this.form.isEndClass = false
        }
        if (res.data.punchPoint != null) {
          let arr = res.data.punchPoint.split(',')
          this.form.clockList = []
          arr.map(v => {
            this.form.clockList.push({ val: v })
          })
        }
        console.log(this.form)
      }
    },
    async setClassClockData() {
      if (this.form.punchType == null || this.form.punchType == '') this.form.punchType = 2
      const [, err] = await to(setClassClock(this.form))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('设置成功')
      this.dialogVisible = false
    },
    handleSubmit() {
      if (this.form.isSlide) {
        this.form.punchType = 1
      } else {
        this.form.punchType = null
      }

      if (this.form.isDrag) {
        this.form.afterStudyDrag = 1
      } else {
        this.form.afterStudyDrag = 2
      }

      if (this.form.isSpeed) {
        this.form.doubleSpeed = 1
      } else {
        this.form.doubleSpeed = 2
      }

      if (this.form.isEndClass) {
        this.form.classFinishContinueLearn = 1
      } else {
        this.form.classFinishContinueLearn = 2
      }
      if (this.form.isAlwaysDoubleSpeed) {
        this.form.alwaysDoubleSpeed = 1
      } else {
        this.form.alwaysDoubleSpeed = 2
      }
      this.form.punchPoint = this.form.clockList
        .map(v => {
          return v.val
        })
        .join(',')

      if (this.form.isSlide && this.form.punchPattern == 1) {
        if (this.form.clockList.length == 1) {
          if (this.form.clockList[0].val == '') {
            this.$message.warning('当前打卡点不能为空')
          } else {
            this.setClassClockData()
          }
        }
        if (this.form.clockList.length == 2) {
          if (this.form.clockList[0].val == '' || this.form.clockList[1].val == '') {
            this.$message.warning('当前打卡点不能为空')
          }
          if (Number(this.form.clockList[0].val) > Number(this.form.clockList[1].val)) {
            this.$message.warning('当前打卡点不能小于前一次打卡点')
          } else {
            this.setClassClockData()
          }
        } else if (this.form.clockList.length == 3) {
          if (
            this.form.clockList[0].val == '' ||
            this.form.clockList[1].val == '' ||
            this.form.clockList[2].val == ''
          ) {
            this.$message.warning('当前打卡点不能为空')
          }
          if (
            Number(this.form.clockList[0].val) > Number(this.form.clockList[2].val) ||
            Number(this.form.clockList[1].val) > Number(this.form.clockList[2].val)
          ) {
            this.$message.warning('当前打卡点不能小于前一次打卡点')
          } else {
            this.setClassClockData()
          }
        }
      } else {
        this.setClassClockData()
      }
    },
    changeClockNum(index) {
      this.choiceIndex = index
      this.form.punchNum = index + 1
      let arr = [{ val: '' }]
      if (this.choiceIndex !== 0) {
        for (var i = 0; i < this.choiceIndex; i++) {
          arr.push({ val: '' })
        }
      }
      this.form.clockList = arr
      console.log(this.form.clockList)
    },
    cancel() {
      let obj = {
        isDrag: true,
        isSpeed: true,
        isEndClass: true,
        isSlide: false,
        isAlwaysDoubleSpeed: false,
        isExamDependCourse: true,
        clockList: [{ val: '' }],
        punchNum: 1, //	打卡次数
        punchPattern: '', //打卡模式
        punchPoint: '', //打卡点
        punchType: '', //打卡类型
        afterStudyDrag: 0,
        classFinishContinueLearn: 0,
        doubleSpeed: 0,
        alwaysDoubleSpeed: 0,
        classId: '',
      }
      this.dialogVisible = false
      this.form = obj
    },
  },
}
</script>

<style lang="scss" scoped>
.punchRules {
  padding-left: 30px;
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .ruleDetail {
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .detailItem {
      width: 50%;
      margin: 10px 0;
    }
  }
  .slideDetail,
  .info {
    padding-left: 30px;
    .info {
      //   margin: 20px 0;
      .num {
        display: inline-block;
        width: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #ff7b33;
        margin: 0 5px;
        border-radius: 6px;
        color: #ff7b33;
        cursor: pointer;
      }
      .infoItem {
        padding: 20px 0;
        .text {
          color: #909399;
          margin-bottom: 20px;
        }
        .progrseeWrap {
          display: flex;
          .progrsee {
            display: flex;
            align-items: center;
            margin: 0 10px;
            &:first-child {
              margin-left: 0;
            }
          }
          .input-width {
            width: 80px;
            margin: 0 8px;
          }
        }
      }
    }
  }
}
.bgc {
  background-color: #ff7b33 !important;
  color: #fff !important;
}
</style>
