<!--
 * @Description: 班级列表
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:45:28
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-23 16:49:28
-->

<template>
  <div class="class-list">
    <table-list
      title="班级列表"
      :loading="loading"
      :data="classList"
      :btns="btns(this)"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :operates2="operates2(this)"
      :pager="pager"
      :options="{ selection: true, operatesWidth: 403, fixScrollTable: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <Rules ref="Rules"></Rules>
    <SetUpEvaluate ref="SetUpEvaluate"></SetUpEvaluate>
    <el-dialog
      custom-class="deleteDialog"
      title="确定删除"
      width="400px"
      :visible.sync="deleteDialogVisible"
    >
      <p>
        <i class="el-icon-warning"></i>
        确定要删除以下班级吗？ <span>删除后将无法恢复</span>
      </p>
      <p>
        班级名称:<b>{{ deleteRow.className }}</b>
      </p>

      <div class="btn">
        <el-button class="delBtn" @click="deleteDialogVisible = false">取消</el-button>
        <el-button
          :disabled="deleteWaiting > 0"
          type="primary"
          class="delBtn"
          @click="deleteConfirm"
          >确定删除 <template v-if="deleteWaiting > 0">({{ deleteWaiting }}S)</template></el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
const btns = _this => [
  {
    label: '创建班级',
    type: 'primary',
    method: _this.toCreate,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '班级名称',
    prop: 'keyword',
    width: 150,
  },
  {
    label: '班主任姓名',
    prop: 'headTeacherName',
    width: 120,
  },
  {
    label: '课程名称',
    prop: 'courseName',
    width: 120,
  },
  {
    label: '班级状态',
    type: 'select',
    width: 120,
    prop: 'status',
    children: [
      { value: 0, label: '编辑中' },
      { value: 1, label: '待培训' },
      { value: 2, label: '培训中' },
      { value: 3, label: '已结训' },
      { value: 4, label: '已取消' },
      // { value: 4, label: '解散关闭' },
    ],
  },
]
// 表格列配置
const columns = () => [
  // {
  //   prop: 'classId',
  //   label: '班级编号',
  //   width: 80,
  // },
  {
    prop: 'className',
    label: '班级名称',
    minWidth: 100,
  },
  {
    prop: 'headTeacher',
    label: '班主任',
    formatter: row => {
      if (row.headTeacher.length) {
        let nameArray = row.headTeacher.map(element => element.studentName)
        return nameArray.join('/')
      } else {
        return ''
      }
    },
    minWidth: 100,
  },
  {
    prop: 'studentTotal',
    label: '班级人数',
    minWidth: 80,
    align: 'right',
  },
  {
    prop: 'courses',
    label: '课程',
    // tooltip: true,
    formatter: row => {
      if (row.courses.length) {
        let nameArray = row.courses.map(element => element.courseName)
        return nameArray.join('/')
      } else {
        return ''
      }
    },
    minWidth: 150,
  },
  {
    prop: 'durationTotal',
    label: '总培训时长(分钟)',
    minWidth: 150,
    formatter: row => {
      return row.durationTotal
    },
    align: 'right',
  },
  {
    prop: 'trainProgress',
    label: '培训进度',
    formatter: row => {
      return row.trainProgress + '%'
    },
    minWidth: 80,
    align: 'right',
  },
  {
    prop: 'status',
    label: '班级状态',
    formatter: row => {
      return ['编辑中', '待培训', '培训中', '已结训', '已取消'][row.status]
    },
    minWidth: 80,
  },
  {
    prop: 'classBeginTime',
    label: '开班时间',
    minWidth: 150,
  },
  {
    prop: 'classEndTime',
    label: '结束时间',
    minWidth: 150,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '添加员工',
    // color: '#70b603',
    type: 'success',
    isShow: row => row.status < 3,
    method: _this.handleAddStudent,
  },
  {
    label: '添加课程',
    // color: '#70b603',
    type: 'success',
    isShow: row => row.status < 3,
    method: _this.handleAddCourse,
  },
  {
    label: '重新核算',
    // color: '#70b603',
    type: 'success',
    isShow: row => (row.status == 1 || row.status == 2) && row.settleMark,
    method: _this.handleOffer,
  },
  {
    label: '取消班级',
    type: 'info',
    isShow: row => row.status == 0 || row.status == 1,
    // color: '#979797',
    method: _this.handleCancel,
  },
]
const operates2 = _this => [
  {
    label: '确认开班',
    type: 'primary',
    isShow: row => row.status == 0 && row.courses.length && row.studentTotal,
    method: _this.handleOffer,
  },
  {
    label: '班级详情',
    type: 'primary',
    isShow: row => row.status == 2 || row.status == 1 || row.status == 3 || row.status == 4,
    method: _this.handleClassDetail,
  },
  {
    label: '学习规则',
    type: 'primary',
    method: _this.handleStudyRule,
    // isShow: row => row.status != 4,
  },
  {
    label: '学习详情',
    type: 'primary',
    isShow: row => row.status == 2 || row.status == 1 || row.status == 3,
    method: _this.handleStudyInfo,
  },
  {
    label: '修改信息',
    type: 'primary',
    isShow: row => row.status < 3,
    method: _this.handleEdit,
  },
  {
    label: '删除',
    type: 'danger',
    isShow: row => row.status > 2,
    method: _this.handleDelete,
  },
  {
    label: '设置评价',
    type: 'primary',
    isShow: row => row.status == 1 || row.status == 2 || row.status == 3,
    method: _this.onSetUpEvaluate,
  },
  {
    label: '评价记录',
    type: 'primary',
    isShow: row => row.status == 2 || row.status == 3,
    method: _this.onEvaluationRecord,
  },
  // {
  //   label: '导出学习记录',
  //   isShow: row => row.status == 2 || row.status == 1,
  //   method: _this.handleStudyExport,
  // },
]
let _deleteTimer
import TableList from '@/components/TableList'
import Rules from './components/Rules.vue'
import SetUpEvaluate from './components/SetUpEvaluate.vue'
import { classList, studentExport, classCancel, classDelete } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassList',
  components: {
    TableList,
    Rules,
    SetUpEvaluate,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      operates2,
      loading: false,
      classList: [],
      selectClass: [],
      deptList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      deleteRow: {},
      deleteWaiting: 5,
      deleteDialogVisible: false,
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    //设置评价
    onSetUpEvaluate(row) {
      this.$refs.SetUpEvaluate.show(row.classId)
    },
    //评价记录
    onEvaluationRecord(row) {
      this.$router.push(`/class/evaluate/${row.classId}`)
    },

    async getClasses() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(classList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.classList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getClasses()
    },
    toCreate() {
      this.$router.push(`/class/add`)
    },
    handleStudyRule(row) {
      this.$refs.Rules.show(row.classId)
    },
    handleAddStudent(row) {
      this.$router.push(`/class/addStudent/${row.classId}`)
    },
    handleAddCourse(row) {
      this.$router.push(`/class/addCourse/${row.classId}`)
    },
    handleOffer(row) {
      this.$router.push(`/class/offer/${row.classId}`)
    },
    handleStudyInfo(row) {
      this.$router.push(`/class/studyInfo/${row.classId}/${row.className}`)
    },
    handleClassDetail(row) {
      this.$router.push(`/class/classDetail/${row.classId}`)
    },
    async handleStudyExport(row) {
      const [res, err] = await to(studentExport({ classId: row.classId }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '班级员工学习记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 编辑
    handleEdit(row) {
      this.$router.push(`/class/edit/${row.classId}?status=${row.status}`)
    },
    // 删除
    handleDelete(row) {
      clearInterval(_deleteTimer)
      this.deleteWaiting = 5
      _deleteTimer = setInterval(() => {
        this.deleteWaiting--
        if (this.deleteWaiting == 0) {
          clearInterval(_deleteTimer)
        }
      }, 1000)
      this.deleteRow = row
      this.deleteDialogVisible = true
    },
    // 删除
    async deleteConfirm() {
      if (this.deleteWaiting > 0) return
      const { classId } = this.deleteRow
      const [, err] = await to(classDelete({ classId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('删除成功')
      this.deleteDialogVisible = false
      this.getClasses()
    },
    // 取消
    async handleCancel({ classId }) {
      this.$confirm('您确定要取消班级吗?', '确定取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(classCancel({ classId }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('取消成功')
          this.getClasses()
        })
        .catch(() => {})
    },
    // 多选
    handleSelectionChange(val) {
      this.selectClass = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClasses()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClasses()
    },
  },
}
</script>
<style lang="scss" scoped>
.class-list {
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    text-align: left;
  }
  .deleteDialog {
    span {
      color: red;
    }
    p {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    i {
      font-size: 20px;
      color: #e6a23c;
      margin-right: 5px;
    }
    b {
      margin-left: 10px;
    }
    .btn {
      text-align: right;
      .el-button {
        &.is-disabled {
          background-color: #ccc !important;
        }
      }
    }
  }
}
.delBtn {
  height: 32px;
}
</style>
